import BlockIcon from '@mui/icons-material/Block'
import CloseIcon from '@mui/icons-material/Close'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'
import SendIcon from '@mui/icons-material/Send'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import { CreateGroupIcon } from 'components/icons'
import { ReactNode } from 'react'
import './index.css'

interface ModalConfirmComponentProps {
  isShow: boolean
  title?: string
  subtitle?: string
  body?: string | JSX.Element
  actionIcon?: string
  actionText?: any
  actionColor?: string
  width?: string
  height?: string
  onClose?: () => void
  onActionCancel?: () => void
  onActionSubmit?: () => Promise<void>
}

const ModalConfirmComponent = (props: ModalConfirmComponentProps): JSX.Element => {
  const {
    isShow,
    title,
    subtitle,
    body,
    actionIcon,
    actionText,
    actionColor,
    width,
    height,
    onClose,
    onActionCancel,
    onActionSubmit,
  } = props ?? {}
  const dialogPaperProps = width
    ? {
        width,
        minWidth: width,
      }
    : { borderRadius: '12px' }
  const dialogContentSystemProps = height ? { height } : {}
  const lookupActionIcon: Record<string, ReactNode> = {
    redInfoIcon: (
      <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10 mt-4'>
        <svg xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32' fill='none'>
          <path
            d='M16.4998 21.3457V14.679M16.4998 10.679V10.6657M29.8332 15.9993C29.8332 23.3631 23.8636 29.3327 16.4998 29.3327C9.13604 29.3327 3.1665 23.3631 3.1665 15.9993C3.1665 8.63555 9.13604 2.66602 16.4998 2.66602C23.8636 2.66602 29.8332 8.63555 29.8332 15.9993Z'
            stroke='#FF2937'
            strokeWidth='2'
            strokeLinecap='round'
          />
        </svg>
      </div>
    ),
    redBlockIcon: <BlockIcon sx={{ fontSize: 32, color: '#FF2937', marginTop: 4 }} />,
    yellowPowerIcon: <PowerSettingsNewOutlinedIcon sx={{ fontSize: 32, color: '#E5C265', marginTop: 4 }} />,
    yellowSendIcon: <SendIcon sx={{ fontSize: 32, color: '#E5C265', marginTop: 4 }} />,
    yellowSubmitIcon: <TaskAltOutlinedIcon sx={{ fontSize: 32, color: '#E5C265', marginTop: 4 }} />,
    groupIcon: <CreateGroupIcon />,
  }

  return (
    <Dialog
      open={isShow}
      onClose={onActionCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      scroll='paper'
      PaperProps={{ sx: { ...dialogPaperProps } }}
      data-testid={'modal-dialog'}
    >
      {onClose && (
        <IconButton onClick={onClose} style={{ position: 'absolute', top: '1px', right: '8px' }}>
          <CloseIcon />
        </IconButton>
      )}
      {actionIcon && actionIcon in lookupActionIcon && <center>{lookupActionIcon[actionIcon]}</center>}
      {title && (
        <center>
          <p className='text-lg font-semibold leading-4 text-headings  mt-4'>{title}</p>
          {subtitle && <p className='text-base font-medium text-gray-600  mt-4'>{subtitle}</p>}
        </center>
      )}
      {body && (
        <DialogContent sx={{ ...dialogContentSystemProps }}>
          <center>{body}</center>
        </DialogContent>
      )}
      <div className='flex gap-3 justify-center items-center'>
        {onActionCancel && (
          <button
            type='button'
            data-autofocus
            onClick={onActionCancel}
            className={`h-[44px] min-w-[112px] justify-center rounded-lg bg-custom-gray py-2 px-5 text-sm font-medium  border-none`}
            data-testid={'modal-dialog-action-cancel'}
          >
            Cancel
          </button>
        )}
        {onActionSubmit && (
          <button
            type='button'
            onClick={() => {
              onActionSubmit().catch((e: Error) => console.error(e))
            }}
            className={`h-[44px] min-w-[112px] justify-center rounded-lg ${
              actionColor ?? 'bg-custom-yellow'
            } py-2 px-5 text-sm font-medium border-none`}
            data-testid={'modal-dialog-action-submit'}
          >
            {actionText ?? 'Submit'}
          </button>
        )}
      </div>
      <br />
    </Dialog>
  )
}

export default ModalConfirmComponent
