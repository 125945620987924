import { MessageSendIcon } from 'components/icons'
import React, { useEffect, useRef, useState } from 'react'
interface RoomMsgTextAreaComponentProps {
  onSend: (newValue?: string) => void
}

const RoomMsgTextAreaComponent: React.FC<RoomMsgTextAreaComponentProps> = ({ onSend }) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [value, setValue] = useState<string>()
  const [isTextPresent, setIsTextPresent] = useState<boolean>(false)

  useEffect(() => {
    // Initialize height once on mount
    const target = textareaRef.current
    if (target) {
      target.style.height = '48px' // Initial height
    }
  }, [])

  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.currentTarget
    target.style.height = '48px' // Reset to initial height to recalculate
    target.style.height = `${Math.max(target.scrollHeight, 48)}px` // Set height based on content
    setValue(target.value)
    setIsTextPresent(target.value.length > 0)
  }

  return (
    <div className='flex items-center w-full gap-3'>
      <textarea
        ref={textareaRef}
        className={`w-full rounded-lg border border-gray-300 bg-white shadow-xs focus:outline-none h-36 px-6 py-3 items-start`}
        style={{ overflow: 'hidden', resize: 'none', minHeight: '48px' }}
        onInput={handleInput}
      />
      {isTextPresent && (
        <button
          onClick={() => {
            // Handle send action
            if (textareaRef.current) {
              textareaRef.current.value = ''
              onSend(value)
              setValue('')
              setIsTextPresent(false)
              textareaRef.current.style.height = '48px' // Reset to initial height
            }
          }}
        >
          <MessageSendIcon />
        </button>
      )}
    </div>
  )
}

export default RoomMsgTextAreaComponent
