import { BackIcon } from 'components/icons'
import React from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

interface BackFixedComponentProps {
  onClick?: () => void
  leftElement: JSX.Element
  rightElement?: JSX.Element
}

const BackFixedComponent = (props: BackFixedComponentProps): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()
  const { onClick, leftElement, rightElement } = props ?? {}

  const handleBackClick = () => {
    if (onClick) onClick()
    else navigate(-1)
  }

  return (
    <div
      className='fixed inset-x-0 top flex justify-between items-center h-[74px] p-4 bg-white shadow-md'
      style={{
        paddingTop: `env(safe-area-inset-top)`,
        paddingBottom: 'env(safe-area-inset-bottom)',
      }}
    >
      <div className='flex items-center gap-2.5'>
        <div className='cursor-pointer' onClick={handleBackClick} data-testid='back-fixed-button-testid'>
          <BackIcon />
        </div>
        {leftElement}
      </div>
      <div>{rightElement}</div>
    </div>
  )
}

export default BackFixedComponent
