import Avatar from '@mui/material/Avatar'
import './index.css'

const getHslColor = (str: string, s: number = 30, l: number = 80): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return `hsl(${hash % 360}, ${s}%, ${l}%)`
}

const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

const AvatarComponent = (props: any): JSX.Element => {
  const { name } = props
  const children: JSX.Element = <Avatar sx={{ bgcolor: getHslColor(name) }}>{getInitials(name)}</Avatar>
  return children
}

export default AvatarComponent
