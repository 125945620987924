import { useMutation, useQuery } from '@apollo/client'
import { LinearProgress } from '@mui/material'
import RoomMsgTextAreaComponent from 'components/room-msg-textarea'
import TextAreaComponent from 'components/textarea'
import ToastComponent from 'components/toast'
import { useEffect, useRef, useState } from 'react'
import { UserRoomsOutputProps } from 'types/RoomTypes'
import { CREATE_MESSAGE, LIST_MESSAGES_FOR_ROOM, ON_CREATE_MESSAGE_BY_ROOM_ID } from 'utils/messages'
import {
  CreateMessageMutationProps,
  ListMessagesForRoomQueryProps,
  ListRoomsQueryProps,
  MessageDataProps,
  MessageProps,
  OnCreateMessageByRoomIdSubscriptionProps,
  UserProps,
} from 'utils/types'

interface RoomComponentProps {
  roomId: string
  userId: string
}

const RoomComponent: React.FC<RoomComponentProps> = ({ roomId, userId }) => {
  const [messageContent, setMessageContent] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  // hooks
  useEffect(() => {
    // Initialize height once on mount
    const target = textareaRef.current
    if (target) {
      target.style.height = '48px' // Initial height
    }
  }, [])

  // Query to list messages for the selected room
  // const {
  //   data: messagesData,
  //   loading: messagesLoading,
  //   subscribeToMore,
  // } = useQuery<ListMessagesForRoomQueryProps>(LIST_MESSAGES_FOR_ROOM, {
  //   skip: !roomId,
  //   variables: { roomId: roomId },
  // })

  // Subscribe to new messages in the selected room
  // useEffect(() => {
  //   if (!roomId) return

  //   const unsubscribe = subscribeToMore<OnCreateMessageByRoomIdSubscriptionProps>({
  //     document: ON_CREATE_MESSAGE_BY_ROOM_ID,
  //     variables: { roomId: roomId },
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev
  //       const newMessage = subscriptionData.data.onCreateMessageByRoomId
  //       return {
  //         ...prev,
  //         listMessagesForRoom: {
  //           ...prev.listMessagesForRoom,
  //           items: [...(prev.listMessagesForRoom?.items || []), newMessage],
  //         },
  //       }
  //     },
  //   })

  //   return () => unsubscribe()
  // }, [roomId, subscribeToMore])

  // Send Message
  // const [createMessage] = useMutation<CreateMessageMutationProps>(CREATE_MESSAGE)
  const handleSendMessage = async () => {
    // if (!roomId || messageContent.trim() === '') return
    // try {
    //   await createMessage({
    //     variables: { input: { roomId: roomId, content: { text: messageContent } } },
    //   })
    //   setMessageContent('')
    // } catch (error) {
    //   console.error('Error sending message:', error)
    // }
  }

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/* **************** Body **************** */}
      <div className='flex flex-col flex-1 self-stretch p-4 px-5 gap-2 bg-[#F9F9F9]'>
        {/* Messages section with flex-grow and overflow to allow scrolling */}
        {/* <div className='messages w-full flex-grow overflow-y-auto space-y-4 p-4 border rounded-lg'>
          {messagesData?.listMessagesForRoom?.items?.map((msg: MessageDataProps) => (
            <div
              key={msg.id}
              className={`p-2 rounded-lg ${msg.owner === currentUser ? 'bg-blue-100 text-right' : 'bg-gray-100 text-left'}`}
            >
              <strong>{msg.owner === currentUser ? 'You' : msg.owner}</strong>: {msg.content.text}{' '}
              <small>{msg.createdAt}</small>
            </div>
          ))}
        </div> */}
        {/* Input section pinned to the bottom */}
        {/* **************** Continue Button **************** */}
        <div className='fixed bottom-0 left-0 right-0 p-5'>
          <RoomMsgTextAreaComponent onSend={(v?: string) => console.log(v)} />
        </div>
      </div>
    </>
  )
}

export default RoomComponent
