import {
  AddRoomUsersInputProps,
  CreateRoomInputProps,
  RemoveRoomUsersInputProps,
  RoomType,
  UpdateRoomInputProps,
} from 'types/RoomTypes'
import { httpBearerAuth, processError } from 'utils/requests'
import { ResponseProps } from 'utils/types'

export const createRoom = async (
  roomType: RoomType,
  roomUsers: string[],
  roomName?: string,
  roomDescription?: string,
  roomCommunityId?: string,
): Promise<ResponseProps> => {
  try {
    const body: CreateRoomInputProps = { roomType, roomUsers, roomName, roomDescription, roomCommunityId }
    const { data } = await httpBearerAuth('post', `/api/rooms`, '', body as unknown as Record<string, unknown>)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateRoom = async (
  roomId: string,
  roomType?: RoomType,
  roomName?: string,
  roomDescription?: string,
  roomAdmins?: string[],
): Promise<ResponseProps> => {
  try {
    const body: UpdateRoomInputProps = { roomType, roomName, roomDescription, roomAdmins }
    const { data } = await httpBearerAuth('patch', `/api/rooms/${roomId}`, '', body as Record<string, unknown>)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const deleteRoom = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('delete', `/api/rooms/${roomId}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getRoom = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms/${roomId}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getRooms = async (): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getRoomUsers = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms/${roomId}/users`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const addRoomUsers = async (roomId: string, roomUsers: string[]): Promise<ResponseProps> => {
  try {
    const body: AddRoomUsersInputProps = { roomUsers }
    const { data } = await httpBearerAuth(
      'post',
      `/api/rooms/${roomId}/users`,
      '',
      body as unknown as Record<string, unknown>,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const removeRoomUsers = async (roomId: string, roomUsers: string[]): Promise<ResponseProps> => {
  try {
    const body: RemoveRoomUsersInputProps = { roomUsers }
    const { data } = await httpBearerAuth(
      'delete',
      `/api/rooms/${roomId}/users`,
      '',
      body as unknown as Record<string, unknown>,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
