import CenterComponent from 'components/center'
import MetricsComponent from 'components/metrics'
import ToastComponent from 'components/toast'
import TypeaheadComponent from 'components/typeahead'
import { useEffect, useState } from 'react'
import { getSchool, getUsers } from 'utils/schools'
import { MessageProps, SchoolUsersProps } from 'utils/types'
import './index.css'

function Index(): JSX.Element {
  const [schoolName, setSchoolName] = useState<string>()
  const [schoolUsers, setSchoolUsers] = useState<SchoolUsersProps | null>()
  const [msg, setMsg] = useState<MessageProps>()

  useEffect(() => {
    if (schoolName) {
      (async () => {
        const { data, error } = await getUsers(schoolName)
        if (!error) setSchoolUsers({ ...data, schoolName } as SchoolUsersProps)
      })()
    }
  }, [schoolName]) // eslint-disable-line

  return (
    <>
      <CenterComponent>
        <TypeaheadComponent
          onChange={async (v: any) => {
            if (!v || !v.communityName || !v.communityId) return
            let schoolId: string = `${v.communityName}-${v.communityId}`
            const { msg, data, error } = await getSchool(schoolId)
            if (error) setMsg(msg)
            else if (data?.status === 'missing') schoolId = v.communityName
            setSchoolName(schoolId)
          }}
        />
        {msg ? (
          <ToastComponent
            style={msg?.style}
            heading={msg?.heading}
            text={msg?.text}
            onClose={() => setMsg(undefined)}
          />
        ) : (
          <></>
        )}
      </CenterComponent>
      {schoolUsers && <MetricsComponent schoolStats={schoolUsers?.schoolStats} />}
    </>
  )
}
export default Index
