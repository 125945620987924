import { Grid, IconButton, LinearProgress } from '@mui/material'
import AvatarComponent from 'components/avatar'
import BackComponent from 'components/back'
import BackFixedComponent from 'components/back-fixed'
import { DeleteGroupIcon, ExitGroupIcon, GroupInfoIcon, PrivateGroupIcon, PublicGroupIcon } from 'components/icons'
import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import RoomComponent from 'components/room'
import RoomAddUsersComponent from 'components/room-add-users'
import RoomCreateComponent from 'components/room-create'
import RoomInfoComponent from 'components/room-info'
import RoomJoinComponent from 'components/room-join'
import RoomsComponent from 'components/rooms'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { RoomType, RoomUsersOutputProps, UserRoomsOutputProps } from 'types/RoomTypes'
import { UsersOutputProps } from 'types/UserTypes'
import { isWeb, MIN_ROOM_ADMINS, parseJwt } from 'utils/constants'
import { addRoomUsers, deleteRoom, getRooms, getRoomUsers, removeRoomUsers, updateRoom } from 'utils/rooms'
import { MessageProps, StudentProps, UserProps } from 'utils/types'
import { getUsers } from 'utils/users'

interface Props {
  tokens: any
  user: Pick<UserProps, 'name' | 'relation'>
  students: StudentProps[]
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams] = useSearchParams()
  const navigate: NavigateFunction = useNavigate()

  const roomId = searchParams.get('roomId') ?? ''
  const action = searchParams.get('action') ?? ''
  const comId = searchParams.get('comId') ?? ''

  // INPUTS
  const { tokens, students, onUpdate } = props
  const { IdToken } = tokens
  const userId: string = parseJwt(IdToken).phone_number

  // Get communities enrolled by current user
  const communityIds: string[] = students.map((v) => v.schoolName)
  const communities: Record<string, { studentId: string; isNeedPMPool: boolean }> = students.reduce(
    (acc: Record<string, { studentId: string; isNeedPMPool: boolean }>, student: StudentProps) => {
      const { schoolName, studentId } = student
      const pmLocation = student?.dropoffLocation ?? { address: '' }
      const isNeedPMPool = pmLocation && pmLocation?.lat && pmLocation?.lng ? true : false
      acc[schoolName] = { studentId, isNeedPMPool }
      return acc
    },
    {},
  )

  // STATES
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const [rooms, setRooms] = useState<UserRoomsOutputProps[]>([])
  const [users, setUsers] = useState<UsersOutputProps[]>([])
  const [room, setRoom] = useState<UserRoomsOutputProps | null>(null)
  const [roomUsers, setRoomUsers] = useState<RoomUsersOutputProps[]>([])
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // HANDLERS
  const handleUpdateRoomAdmin = async (id: string, admins: string[]) => {
    setIsLoading('Updating room admins...')
    const { msg, error } = await updateRoom(id, undefined, undefined, undefined, admins)
    if (error) setMsg(msg)
    else setRefreshKey((prev) => prev + 1)
    setIsLoading('')
  }
  const handleAddRoomUser = async (id: string, users: string[]) => {
    setIsLoading('Adding users to room...')
    const { msg, error } = await addRoomUsers(id, users)
    if (error) setMsg(msg)
    else navigate(`/messaging?roomId=${roomId}`)
    setIsLoading('')
  }
  const handleRemoveRoomUser = async (id: string, users: string[]) => {
    setIsLoading('Removing users from room...')
    const { msg, error } = await removeRoomUsers(id, users)
    if (error) setMsg(msg)
    else setRefreshKey((prev) => prev + 1)
    setIsLoading('')
  }
  const handleDeleteRoom = async (id: string) => {
    setIsLoading('Deleting room...')
    const { msg, error } = await deleteRoom(id)
    if (error) setMsg(msg)
    else navigate('/messaging')
    setIsLoading('')
  }

  // HOOKS
  useEffect(() => {
    (async () => {
      setIsLoading('Loading rooms and users...')
      const [res1, res2] = await Promise.all([getRooms(), getUsers()])
      if (res1.error || res2.error) setMsg(msg)
      else if (res1.data && res2.data) {
        const r = res1.data.rooms as UserRoomsOutputProps[]
        const u = res2.data.users as UsersOutputProps[]
        setRooms(r)
        setUsers(u)
        if (roomId) {
          const sr = r.find((v) => v.roomId === roomId && v.isMember)
          if (sr) {
            setRoom(sr)
            const res = await getRoomUsers(roomId)
            if (res.error) setMsg(msg)
            else if (res.data) {
              const ru = res.data.users as RoomUsersOutputProps[]
              setRoomUsers(ru)
            }
          } else navigate('/messaging')
        } else {
          setRoom(null)
          setRoomUsers([])
        }
      }
      setIsLoading('')
    })()
  }, [refreshKey, action, roomId, msg, navigate])

  // REUSABLE JSX ELEMENTS
  // Needs all rooms as seen by the user to view, communities used to filter
  const roomsElement: JSX.Element = (
    <RoomsComponent rooms={rooms} isShowCommunityName={communityIds?.length > 1 ? true : false} />
  )
  // Needs the room and the user
  const roomElement: JSX.Element = <RoomComponent roomId={roomId} userId={userId} />
  // Needs all users as seen by the user to create room, private rooms to reuse, communities used to filter, onUpdate just to be safe & for consistency
  const createRoomElement: JSX.Element = (
    <RoomCreateComponent
      users={users}
      privateRooms={rooms.filter((v) => v.roomType === RoomType.PRIVATE)}
      communityIds={communityIds}
      defaultCommunityId={comId}
      userId={userId}
      onUpdate={onUpdate}
    />
  )
  // Needs all rooms as seen by the user to join, communities used to validate user has PM carpool before joining, onUpdate to sync updated student activities
  const joinRoomElement: JSX.Element = (
    <RoomJoinComponent rooms={rooms} communities={communities} userId={userId} onAddRoomUsers={handleAddRoomUser} />
  )
  // Needs all users as seen by the user to add to a room, room to add, communities used to filter & validate, onUpdate just to be safe & for consistency
  const addUserElement: JSX.Element = room ? (
    <RoomAddUsersComponent
      users={users}
      room={room}
      roomUsers={roomUsers}
      userId={userId}
      onAddRoomUsers={handleAddRoomUser}
    />
  ) : (
    <></>
  )
  // Needs the room and the user
  const roomInfoElement = room ? (
    <RoomInfoComponent
      room={room}
      roomUsers={roomUsers}
      userId={userId}
      onUpdateRoomAdmins={handleUpdateRoomAdmin}
      onRemoveRoomUsers={handleRemoveRoomUser}
    />
  ) : (
    <></>
  )

  // reusable jsx elements
  const webElement: JSX.Element = (
    <Grid container alignItems='center' justifyContent='center'>
      <Grid item xs={0} sm={0} md={0} lg={0} xl={0}></Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className='flex h-screen'>
          {/* Left side: RoomsComponent */}
          <div className='w-1/3 p-4 border-r border-gray-300'>{roomsElement}</div>

          {/* Right side: RoomComponent (only visible when selectedRoomId is available) */}
          <div className='w-2/3 p-4'>
            {!action && roomId && room && roomElement}
            {action === 'joinRoom' && joinRoomElement}
            {action === 'createRoom' && createRoomElement}
            {action === 'roomAdd' && addUserElement}
            {action === 'roomInfo' && roomId && room && roomInfoElement}
          </div>
        </div>
      </Grid>
      <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
    </Grid>
  )
  const mobileElement: JSX.Element = (
    <>
      {!action && !roomId && (
        <>
          <BackComponent text='Messaging' onClick={() => navigate('/')} />
          {roomsElement}
        </>
      )}
      {!action && roomId && room && (
        <>
          <BackFixedComponent
            onClick={() => navigate(`/messaging`)}
            leftElement={
              <div className='flex items-center gap-2'>
                {room.roomType === RoomType.PRIVATE && <AvatarComponent name={room.roomName} />}
                {room.roomType === RoomType.GROUP && <PrivateGroupIcon width={40} height={40} />}
                {(room.roomType === RoomType.PUBLIC || room.roomType === RoomType.ACTIVITY) && (
                  <PublicGroupIcon width={40} height={40} />
                )}
                <span className='text-[color:var(--Color-Tokens-Text-Modes-Base-Second,#68727D)] text-xl font-semibold'>
                  {room.roomName}
                </span>
              </div>
            }
            rightElement={
              // FIXME: Navigate to user detail page for private rooms
              room.roomType === RoomType.PRIVATE ? (
                <></>
              ) : (
                <IconButton onClick={() => navigate(`/messaging?action=roomInfo&roomId=${roomId}`)}>
                  <GroupInfoIcon />
                </IconButton>
              )
            }
          />
          {roomElement}
        </>
      )}
      {action === 'joinRoom' && (
        <>
          <BackComponent text='Join Public Group' />
          {joinRoomElement}
        </>
      )}
      {action === 'createRoom' && (
        <>
          <BackComponent text='Create Group' />
          {createRoomElement}
        </>
      )}
      {action === 'roomAdd' && roomId && (
        <>
          <BackComponent text='Add Members' />
          {addUserElement}
        </>
      )}
      {action === 'roomInfo' && roomId && room && (
        <>
          <BackComponent
            text='Group Info'
            onClick={() => navigate(`/messaging?roomId=${roomId}`)}
            rightElement={
              <div className='flex gap-3'>
                <ModalConfirmRemoveComponent
                  icon={<ExitGroupIcon />}
                  title='Exit Group'
                  body={`Are you sure you want to leave this group?`}
                  actionText='Exit now'
                  disabled={room.roomAdmins?.includes(userId) && room.roomAdmins?.length === MIN_ROOM_ADMINS}
                  onClick={() => handleRemoveRoomUser(roomId, [userId])}
                />
                {room.roomAdmins?.includes(userId) && (
                  <ModalConfirmRemoveComponent
                    icon={<DeleteGroupIcon />}
                    title='Delete Group'
                    body={`Are you sure you want to delete this group?`}
                    actionText='Delete now'
                    onClick={() => handleDeleteRoom(roomId)}
                  />
                )}
              </div>
            }
          />
          {roomInfoElement}
        </>
      )}
    </>
  )

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {isWeb ? webElement : mobileElement}
    </>
  )
}

export default Index
