import Box from '@mui/material/Box'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { setSessionItem } from 'utils/constants'
import { getSchool, verifySchoolCode } from 'utils/schools'
import { MessageProps } from 'utils/types'

import LinearProgress from '@mui/material/LinearProgress'
import InputMobileComponent from 'components/input-mobile'
import WizardComponent from 'components/wizard'
import './index.css'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name
  const [searchParams] = useSearchParams()
  const schoolName: string = searchParams.get('sn') || ''

  // states
  const [schoolAdmins, setSchoolAdmins] = useState<string[]>()
  const [schoolScheduleGroupNames, setSchoolScheduleGroupNames] = useState<string[]>([])
  const [schoolStatus, setSchoolStatus] = useState<string>('')
  const [schoolCode, setSchoolCode] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()

  // hooks
  useEffect(() => {
    (async () => {
      const { msg, data, error } = await getSchool(schoolName)
      if (error) setMsg(msg)
      else if (data) {
        setSchoolStatus(data.status as string)
        setSchoolAdmins(data?.schoolAdmins as string[])
        setSchoolScheduleGroupNames(
          (data?.schoolScheduleGroups as any[])?.map((group: any) => group.schoolScheduleGroupName),
        )
      }
    })()
  }, [schoolName])

  // handlers
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    const { msg, error } = await verifySchoolCode(schoolName, schoolCode)
    if (error) setMsg(msg)
    else {
      // Save school name and code for following steps
      setSessionItem('x-pool-sn', schoolName)
      setSessionItem('x-pool-sa', schoolAdmins)
      setSessionItem('x-pool-sc', schoolCode)
      setSessionItem('x-pool-ssgn', schoolScheduleGroupNames)
      if (schoolScheduleGroupNames.length > 1) {
        navigate('/signup/school/schedule')
      } else {
        setSessionItem('x-pool-sg', schoolScheduleGroupNames[0])
        navigate('/signup/createUser')
      }
    }
  }
  if (schoolStatus === 'verify_code') {
    return (
      <WizardComponent
        onBack={onBack}
        title={'Enter Your School Code'}
        disable={!schoolCode}
        btnText={'Next'}
        onClick={onNext}
        data_testId={'next-btn-testid'}
      >
        <InputMobileComponent
          type='text'
          placeholder='School code'
          data_testid='school_verification_code_testid'
          onChange={(v: string) => setSchoolCode(v.trimEnd())}
        />
        {schoolAdmins && schoolAdmins?.length >= 1 && (
          <Box pt={1}>
            <div className='text-gray-400 text-sm'>
              No school code? Contact your school admin{` at ${schoolAdmins[0].toString()}`}
            </div>
          </Box>
        )}
        {msg && (
          <ToastComponent
            style={msg?.style}
            heading={msg?.heading}
            text={msg?.text}
            onClose={() => setMsg(undefined)}
          />
        )}
      </WizardComponent>
    )
  } else if (schoolStatus === 'verify_manual') {
    return (
      <WizardComponent
        onBack={onBack}
        title={'Self-Registration Not Enabled'}
        subtitle={`Please contact your school administrator to complete the registration process.`}
      >
        <></>
      </WizardComponent>
    )
  } else if (schoolStatus === 'disabled') {
    return (
      <WizardComponent
        onBack={onBack}
        title={'School Not Enrolled'}
        subtitle={`Ask school/pta to enroll for carpool.school service.`}
      >
        <></>
      </WizardComponent>
    )
  } else if (schoolStatus === 'missing') {
    return (
      <WizardComponent
        onBack={onBack}
        title={'School Missing'}
        subtitle={`Contact us at support@carpool.school to add your school.`}
      >
        <></>
      </WizardComponent>
    )
  } else return <LinearProgress />
}

export default Index
